<template>
  <module
    ref="module"
    id="billingMarketplaceModule"
    titleIcon="fa fa-retweet"
    api-url="MAINAPIURLV4"
    :title="$t('payments')"
    :advanced-filter-title="$t('advanced_filter_title')"
    :visibilityFields.sync="tableColumns"
    :filters.sync="filters"
    :url="`/${userIdUrl}/billing/marketplace`"
    :use-exports="true"
    :export-data-with-checkbox="false"
    :export-header="exportHeader"
    :export-data="exportData"
    :show-advanced-filter="true"
    :show-visibility-button="true"
    @before-export="prepareExportData"
    @before-clear-filters="clearFilters"
  >

    <template slot="toolbar-global">
      <div class="mr-1" v-if="isAdmin">
        <fg-input
          id="userIdSearch"
          type="email"
          v-model="useridFilter"
          addon-right="fa fa-step-forward"
          class="input-toolbar"
          :placeholder="`${$t('userid')}`"
          @change="filterByUser"
        >
          <button
            slot="addonRight"
            class="btn btn-xs btn-primary"
            @click="filterByUser"
          >
            <i class="fa fa-step-forward"></i>
          </button>
        </fg-input>
      </div>
    </template>

    <template slot="global">

        <modal
         id="billingMarketplaceInfoModal"
         :required="true"
         :show.sync="showModal"
         headerClasses="justify-content-center"
         modalClasses="modal-md"
        >
          <h4 slot="header" class="title title-up">
            {{ $t('billinginstructions') }}
            <br>
            <br>
            <badge v-if="handleType(selectedRowData)" :type="handleType(selectedRowData).type" pill>
              {{ handleType(selectedRowData).label }}
            </badge>
          </h4>
          <div :class="`row item-list ${isRecharge(selectedRowData) ? 'recharging' : ''}`">
            <span class="label col-6">{{ $t('strategytxt') }} </span>
            <span :class="`value col-6`" >
              {{ selectedRowData.strategy_name }}
            </span>
            <hr>

            <span class="label col-6">{{ $t('payer') }} {{ $t('fullname') }}</span>
            <span :class="`value col-6`" >
              {{ selectedRowData.follower_full_name }}
            </span>
            <hr>

            <span class="label col-6">{{$t("exchange_to_usd")}}</span>
            <span :class="`value col-6`" >
              {{ parseFloat(selectedRowData.currency_exchange).toFixed(4) }}
            </span>

            <hr>
            <span class="label col-6">{{ $t('profit') }}</span>
            <span :class="`value col-6`" >
              {{ getCurrencySymbol(selectedRowData.follower_currency) }} {{ selectedRowData.watermark_billable_profit_bc }}
            </span>

            <hr v-if="isWaived(selectedRowData)">
            <span
              v-if="isWaived(selectedRowData)"
              class="label col-6">{{$t("waivetxt")}}
            </span>
            <span
                v-if="isWaived(selectedRowData)"
                :class="`value col-6`">
                    <p-button
                      size="sm"
                      type="info"
                      :title="$t('waivetitle')"
                      @click="waive(selectedRowData)"
                    >
                    <i class="fa fa-handshake-o"/>
              </p-button>
            </span>

            <hr>
            <span class="label col-6" >
              {{ $t('adjusttxt') }} {{ $t('performancefee') }}
            </span>
            <span :class="`value col-6`" >
              <fg-numeric
                percentage
                input-classes="xs"
                v-model="new_performance_fee"
                :min="0"
                :max="maxPerformanceFee"
                :step="1"
                :disabled="!canUpdate(selectedRowData)"
                @change="recalc"/>
            </span>

            <hr v-if="selectedRowData.effective_performance_percent > 0">
            <span v-if="selectedRowData.effective_performance_percent > 0" class="label col-6">{{ $t('amount') }}</span>
            <span v-if="selectedRowData.effective_performance_percent > 0" :class="`value col-6 ${isFollower(selectedRowData) ? 'text-danger' : ''}`" >
              {{ getCurrencySymbol(selectedRowData.follower_currency) }} {{ selectedRowData.effective_performance_value_bc }} ({{ selectedRowData.effective_performance_percent }}%)
                <span class="recalc"> {{ recalcRow.effective_performance_value_bc }} </span>
            </span>

            <hr v-if="selectedRowData.hoko_part_percentage > 0"/>
            <span v-if="selectedRowData.hoko_part_percentage > 0" class="label col-6">{{ $t('hokotxt') }} {{ $t('parttxt') }} (%)</span>
            <span v-if="selectedRowData.hoko_part_percentage > 0" :class="`value col-6`">
              {{ getCurrencySymbol(selectedRowData.follower_currency) }} {{ parseFloat(selectedRowData.hoko_part_value_bc).toFixed(2) }} ({{ selectedRowData.hoko_part_percentage }}%)
                <span class="recalc"> {{ recalcRow.hoko_part_value_bc }} </span>
            </span>

            <hr v-if="selectedRowData.trader_part_percentage > 0">
            <span v-if="selectedRowData.trader_part_percentage > 0" class="label col-6">{{ $t('tradertxt') }} {{ $t('parttxt') }} (%)</span>
            <span v-if="selectedRowData.trader_part_percentage > 0" :class="`value col-6 ${isTrader(selectedRowData) ? 'text-success' : ''}`">
              {{ getCurrencySymbol(selectedRowData.follower_currency) }} {{ parseFloat(selectedRowData.trader_part_value_bc).toFixed(2) }} ({{ selectedRowData.trader_part_percentage }}%)
                <span class="recalc"> {{ recalcRow.trader_part_value_bc }} </span>
            </span>

            <hr v-if="selectedRowData.affiliate_part_percentage > 0">
            <span v-if="selectedRowData.affiliate_part_percentage > 0" class="label col-6">{{ $t('affiliatetxt') }} {{ $t('parttxt') }} (%)</span>
            <span v-if="selectedRowData.affiliate_part_percentage > 0" :class="`value col-6 ${isAffiliate(selectedRowData) ? 'text-success' : ''}`">
              {{ getCurrencySymbol(selectedRowData.follower_currency) }} {{ selectedRowData.affiliate_part_value_bc }} ({{ selectedRowData.affiliate_part_percentage }}%)
                <span class="recalc"> {{ recalcRow.affiliate_part_value_bc }} </span>
            </span>

            <hr v-if="recalcRow.wl_part_percentage > 0">
            <span v-if="recalcRow.wl_part_percentage > 0" class="label col-6">{{ $t('whitelabel') }} {{ $t('parttxt') }} (%)</span>
            <span v-if="recalcRow.wl_part_percentage > 0" :class="`value col-6 ${isWhitelabel(selectedRowData) ? 'text-success' : ''}`">
              {{ getCurrencySymbol(selectedRowData.follower_currency) }} {{ selectedRowData.wl_part_value_bc }} ({{ recalcRow.wl_part_percentage }}%)
                <span class="recalc"> {{ recalcRow.wl_part_value_bc }} </span>
            </span>
            <hr>

          </div>
          <template slot="footer">
            <div class="left-side">
              <p-button
                link
                type="success"
                :loading="processing"
                @click="recalcSelectedRowModal"
              >
                {{ $t('confirm') }}
              </p-button>
            </div>
            <div class="divider"></div>
            <div class="right-side">
              <p-button type="danger" link @click="closeSelectedRowModal">
                {{ $t('cancel') }}
              </p-button>
            </div>
          </template>
      </modal>
    </template>

    <template slot="table-columns">
      <el-table-column
          v-if="isAdmin"
          prop="id" :label="$t('id')"
          width="40px"
          show-overflow-tooltip
      />
      <el-table-column
          :prop="tableColumns.info.prop"
          :label="tableColumns.info.label"
          v-if="tableColumns.info.isVisible"
          width="110px"
          align="center"
      >
        <template slot-scope="{row}">
          <p-button
            v-if="isApproved(row)"
            size="sm"
            type="primary"
            class="py-1 px-3"
            round
            outline
            :title="$t('billinginstructions')"
            @click="openSelectedRowModal(row)"
          >
            <i class="fa fa-info"></i>
          </p-button>
        </template>
      </el-table-column>
      <el-table-column
          :label="tableColumns.status.label"
          v-if="tableColumns.status.isVisible"
          width="170px"
          align="center"
      >
        <template slot-scope="{row}">
          <badge :type="handleType(row).type">
            <span class="ellipsis">{{ handleType(row).label }}</span>
          </badge>
        </template>
      </el-table-column>
      <el-table-column
          :prop="tableColumns.date_reference.prop"
          :label="tableColumns.date_reference.label"
          v-if="tableColumns.date_reference.isVisible"
          width="120px"
          align="center"
          sortable
      />
      <el-table-column
          :prop="tableColumns.follower_account.prop"
          :label="tableColumns.follower_account.label"
          v-if="tableColumns.follower_account.isVisible"
          width="100px"
          show-overflow-tooltip
      />
      <el-table-column
          :prop="tableColumns.follower_full_name.prop"
          :label="tableColumns.follower_full_name.label"
          v-if="tableColumns.follower_full_name.isVisible"
          width="260px"
          show-overflow-tooltip
      />
      <el-table-column
          v-if="tableColumns.amount.isVisible"
          :label="getAmountText()"
          width="120px"
          align="center"
      >
        <template slot-scope="{row}">
          <span :class="getColorByOperationType(row)">
            <b v-if="isFollower(row)">$ {{ parseFloat(row.effective_performance_value_usd).toFixed(2) }} </b>
            <b v-if="isTrader(row)">$ {{ parseFloat(row.trader_part_value_usd).toFixed(2) }} </b>
            <b v-if="isAffiliate(row)">$ {{ parseFloat(row.affiliate_part_value_usd).toFixed(2) }} </b>
            <b v-if="isWhitelabel(row)">$ {{ parseFloat(row.wl_part_value_usd).toFixed(2) }} </b>
          </span>
        </template>
      </el-table-column>
      <el-table-column
          :label="tableColumns.percent_from_total.label"
          v-if="tableColumns.percent_from_total.isVisible"
          width="120px"
          align="center"
      >
          <template slot-scope="{row}">
            <span>
             <b v-if="isFollower(row)">{{ Math.round(getPercentage(row.watermark_performance_value_bc, row.watermark_performance_value_bc)) }}% </b>
             <b v-if="isTrader(row)">{{ row.trader_part_percentage }}% </b>
             <b v-if="isAffiliate(row)">{{ row.affiliate_part_percentage }}% </b>
             <b v-if="isWhitelabel(row)">{{ row.wl_part_percentage }}% </b>
            </span>
          </template>
      </el-table-column>
      <el-table-column
          :prop="tableColumns.strategy_name.prop"
          :label="tableColumns.strategy_name.label"
          v-if="tableColumns.strategy_name.isVisible"
      />
    </template>

    <template slot="advanced-filter-field">
      <el-form style="padding-top: 65px;" v-model="filters" label-position="top">
        <el-form-item :label="$t('status')">
          <el-select v-model="filters.status" style="width: 100%" clearable>
            <el-option value="all" :label="$t('alltxt')"></el-option>
            <el-option value="failed" :label="$t('failedtxt')"></el-option>
            <el-option value="pending" :label="$t('pendingtxt')"></el-option>
            <el-option value="success" :label="$t('successtxt')"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('payment_type')">
          <el-select v-model="filters.payment_type" style="width: 100%" clearable>
            <el-option value="none" :label="$t('nodata')"></el-option>
            <el-option value="to_pay" :label="$t('payment_status_to_pay')"></el-option>
            <el-option value="to_receive" :label="$t('payment_type_to_receive')"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('account')">
          <fg-input
            v-model="filters.follower_account"
            type="text"
            replace="[^0-9]"/>
        </el-form-item>
        <el-form-item :label="$t('strategytxt')">
          <fg-input v-model="filters.strategy_name" ></fg-input>
        </el-form-item>
        <el-form-item :label="$t('date')">
          <el-date-picker
            style="width: 100%"
            v-model="date_reference"
            type="daterange"
            unlink-panels
            :start-placeholder="$t('date_begin')"
            :end-placeholder="$t('date_end')">
          </el-date-picker>
        </el-form-item>
      </el-form>
    </template>
  </module>
</template>

<script>

  import {Col, Row, DatePicker} from 'element-ui'
  import Constants from 'src/assets/constants';
  import { Badge, Modal } from 'src/components/UIComponents';
  import swal from 'sweetalert2';
  import { getCurrency } from 'src/util/CurrencyUtils';
  import Vue from "vue";
  import lang from 'element-ui/lib/locale/lang/en'
  import locale from 'element-ui/lib/locale'

  const rules = Constants.methods.getRules();
  const user_data = JSON.parse(localStorage.getItem('user-info'))
  const visibility = localStorage.getItem('visibility_items');
  const json = visibility ? JSON.parse(visibility) : null;

  locale.use(lang)

  Vue.use(Col)
  Vue.use(Row)
  Vue.use(require('vue-moment'));

  export default {
    name: "LedgerMarketplace",
    components: {
      [DatePicker.name]: DatePicker,
      Badge,
      Modal,
    },
    computed: {
      isAdmin() {
        return rules.isAdmin;
      },
      userIdUrl() {
          return this.userid || 'a';
      },
      maxPerformanceFee() {
        return this.max_strategy_price
          && this.max_strategy_price
            ? this.max_strategy_price : 0;
      },
    },
    mounted () {
      this.filters = {
          status: 'all'
      }
    },
    data() {
        let self = this;
        return {
            userid: user_data.email,
            useridFilter: user_data.email,
            new_performance_fee: 0,
            showDisputeModal: false,
            disputeDescription: '',
            selectedRowData: {},
            recalcRow: {},
            max_strategy_price: 0,
            processing: false,
            waitingModal: false,
            showModal: false,
            approve: false,
            date_reference: {},
            filters: {},
            status: {
              pendingPayment: this.$t("pending_payment"),
              waivedPayment: this.$t("waived_by_provider"),
              pendingBrokerDebit: this.$t("pending_broker_debit"),
              failedBrokerDebit: this.$t("failed_broker_debit"),
              paidToHoko: this.$t("paid_to_hoko"),
              debitedByBroker: this.$t("debited_by_broker"),
              failedCollection: this.$t("failed_collection"),
              pendingCollection: this.$t("pending_collection"),
              pendingBrokerCredit: this.$t("pending_broker_credit"),
              failedBrokerCredit: this.$t("failed_broker_credit"),
              receivedFromHoko: this.$t("received_from_hoko"),
              creditedByBroker: this.$t("credited_by_broker"),
              pendingCredit: this.$t("pending_credit"),
            },
            exportHeader: [],
            exportData: [],
            tableColumns: json || {
              info: {
                prop: 'info',
                label: self.$t('infotxt'),
                isVisible: true,
              },
              status: {
                label: self.$t('status'),
                isVisible: true,
              },
              date_reference: {
                prop: 'date_reference',
                label: self.$t('date'),
                isVisible: true,
              },
              follower_account: {
                prop: 'follower_account',
                label: self.$t('account'),
                isVisible: true,
              },
              follower_full_name: {
                prop: 'follower_full_name',
                label: self.$t('payer') + ' ' + self.$t('fullname'),
                isVisible: true,
              },
              amount: {
                label: self.$t('amount'),
                isVisible: true,
              },
              percent_from_total: {
                label: self.$t('percent_from_total'),
                isVisible: true,
              },
              strategy_name: {
                prop: 'strategy_name',
                label: self.$t('strategytxt'),
                isVisible: true,
              },
            },
        }
    },
    methods: {
      async openSelectedRowModal(rowData) {
        this.selectedRowData = rowData;
        this.recalcRow = {};
        await this.getStrategyPrice(this.selectedRowData);
        this.new_performance_fee = rowData.watermark_performance_percent;
        this.showModal = true;
      },
      async recalcSelectedRowModal() {
        this.processing = true;
        const response = await this.$recalculateLedgerProductMarketplace({
          ...this.selectedRowData,
          performance_id: this.selectedRowData.id,
          performance_fee: (this.new_performance_fee / 100),
        }).catch(err => this.processing = false);
        this.processing = false;
        if(this.validateResponse(response)) {
          this.closeSelectedRowModal();
        }
      },
      async getStrategyPrice(row) {
        this.processing = true;
        await this.$getStrategyPrice(row.strategy_id)
            .then((resp) =>{
                this.processing = false;
                this.max_strategy_price = resp.data.strategy_price || 0;
            }, (e) => {
                this.processing = false;
                if (e.data.msg !== undefined) {
                  this.$toast.error(e.data.msg);
                }
                this.showModal = false;
            })
      },
      async waive(row) {
        swal({
          title: this.$t('needconfirmation'),
          text: this.$t('confirmwaive'),
          type: 'warning',
          showCancelButton: true,
        }).then(async () => {
            this.waitingModal = true
            const response = await this.$waiveLedgerProductMarketplace({
              ...row,
              performance_id: row.id,
            });
            if(this.validateResponse(response)) {
              this.filterByUser();
            }
        }).catch(swal.noop);
      },
      getAmountText() {
        return this.getCurrencySymbol(this.selectedRowData.follower_currency) + ' ' + this.$t('amount')
      },
      handleType(row) {
        if (this.isFollower(row)) {
          switch (row.status) {
              case 10:
                  return {
                      label: this.status.pendingPayment,
                      type: 'warning'
                  };
              case 11:
                  return {
                      label: this.status.waivedPayment,
                      type: 'danger'
                  };
              case 20:
                  return {
                      label: this.status.pendingBrokerDebit,
                      type: 'warning'
                  };
              case 21:
                  return {
                      label: this.status.failedBrokerDebit,
                      type: 'danger'
                  };
              case 30:
                  return {
                      label: this.status.paidToHoko,
                      type: 'success'
                  };
              case 31:
                  return {
                      label: this.status.paidToHoko,
                      type: 'success'
                  };
              case 40:
                  return {
                      label: this.status.debitedByBroker,
                      type: 'info'
                  };
              case 41:
                  return {
                      label: this.status.debitedByBroker,
                      type: 'info'
                  };
              case 50:
                  return {
                      label: this.status.paidToHoko,
                      type: 'success'
                  };
              case 60:
                  return {
                      label: this.status.debitedByBroker,
                      type: 'info'
                  };
              case 70:
                  return {
                      label: this.status.paidToHoko,
                      type: 'success'
                  };
              case 80:
                  return {
                      label: this.status.debitedByBroker,
                      type: 'info'
                  };
          }
        } else if (this.isTrader(row)) {
          switch (row.status) {
              case 10:
                  return {
                      label: this.status.pendingCollection,
                      type: 'warning'
                  };
              case 11:
                  return {
                      label: this.status.waivedPayment,
                      type: 'danger'
                  };
              case 20:
                  return {
                      label: this.status.pendingCollection,
                      type: 'warning'
                  };
              case 21:
                  return {
                      label: this.status.failedCollection,
                      type: 'danger'
                  };
              case 30:
                  return {
                      label: this.status.pendingBrokerCredit,
                      type: 'warning'
                  };
              case 31:
                  return {
                      label: this.status.failedBrokerCredit,
                      type: 'danger'
                  };
              case 40:
                  return {
                      label: this.status.pendingBrokerCredit,
                      type: 'warning'
                  };
              case 41:
                  return {
                      label: this.status.failedBrokerCredit,
                      type: 'danger'
                  };
              case 50:
                  return {
                      label: this.status.receivedFromHoko,
                      type: 'success'
                  };
              case 60:
                  return {
                      label: this.status.receivedFromHoko,
                      type: 'success'
                  };
              case 70:
                  return {
                      label: this.status.creditedByBroker,
                      type: 'info'
                  };
              case 80:
                  return {
                      label: this.status.creditedByBroker,
                      type: 'info'
                  };
          }
        } else if (this.isAffiliate(row)) {
          switch (row.status) {
              case 10:
                  return {
                      label: this.status.pendingCollection,
                      type: 'warning'
                  };
              case 11:
                  return {
                      label: this.status.waivedPayment,
                      type: 'danger'
                  };
              case 20:
                  return {
                      label: this.status.pendingCollection,
                      type: 'warning'
                  };
              case 21:
                  return {
                      label: this.status.failedCollection,
                      type: 'danger'
                  };
              case 30: case 31: case 40: case 41:
                  return {
                      label: this.status.pendingCredit,
                      type: 'warning'
                  }
              default:
                  return {
                      label: this.status.receivedFromHoko,
                      type: 'success'
                  };
          }
        } else if (this.isWhitelabel(row)) {
            switch (row.status) {
              case 10:
                  return {
                      label: this.status.pendingCollection,
                      type: 'warning'
                  };
              case 11:
                  return {
                      label: this.status.waivedPayment,
                      type: 'danger'
                  };
              case 20:
                  return {
                      label: this.status.pendingCollection,
                      type: 'warning'
                  };
              case 21:
                  return {
                      label: this.status.failedCollection,
                      type: 'danger'
                  };
              case 30: case 31: case 40: case 41:
                  return {
                      label: this.status.pendingCredit,
                      type: 'warning'
                  }
              default:
                  return {
                      label: this.status.receivedFromHoko,
                      type: 'success'
                  };
          }
        }
      },
      clearFilters() {
        this.filters = {
            status: 'all'
        }
        this.date_reference = {}
      },
      isRecharge(row) {
        if(!this.canUpdate(row)) {
          return false;
        }
        return row.watermark_performance_percent && row.watermark_performance_percent !== this.new_performance_fee;
      },
      isWaived(row) {
        return (row.status === 10 || row.status === 20 || row.status === 21) && this.isTrader(row);
      },
      canUpdate(row) {
        return (this.isTrader(row && !this.processing)) || this.isAdmin;
      },
      getPercentage(part, amount) {
        return parseFloat((part * 100) / amount).toFixed(2);
      },
      affiliatePart(row) {
        return (parseFloat(row.affiliate_part_value_usd) + parseFloat(row.wl_part_value_usd)).toFixed(2);
      },
      isAffiliate(row) {
        return this.$toLowerEquals(row.affiliate_userid, this.userid) && this.userid && !this.isTrader(row);
      },
      isTrader(row) {
        return this.$toLowerEquals(row.trader_userid, this.userid);
      },
      isFollower(row) {
        return this.$toLowerEquals(row.follower_userid, this.userid);
      },
      isWhitelabel(row) {
        return this.$toLowerEquals(row.whitelabel_userid, this.userid);
      },
      isApproved(row) {
        return row.status > 21 || row.status !== 11
      },
      getColorByOperationType(row) {
        return this.$toLowerEquals(row.follower_userid, this.userid) ? 'text-danger' : 'text-success'
      },
      closeSelectedRowModal() {
        this.selectedRowData = {};
        this.showModal = false;
        this.approve = false;
        this.filterByUser();
      },
      validateResponse(response) {
        this.waitingModal = false
        if(!response.success) {
          if(response.msg) {
            this.$toast.warning(this.$t(response.msg));
          } else {
            this.$toast.error(this.$t('unkownerror'));
          }
          return false;
        }
        this.showModal = false;
        this.$toast.success(this.$t(response.msg));
        return true;
      },
      filterByUser() {
        this.userid = this.useridFilter;
        this.$refs.module.search();
      },
      getCurrencySymbol(code) {
        return getCurrency(code);
      },
      recalc() {

        const data = {...this.selectedRowData};
        const rec = {};

        rec.effective_performance_value_bc = (data.effective_performance_value_bc * (this.new_performance_fee / 100)).toFixed(2)

        rec.wl_part_percentage = data.wl_part_percentage
        rec.trader_part_percentage = 1 - (data.hoko_part_percentage / 100) - (data.affiliate_part_percentage / 100) - (data.wl_part_percentage / 100)

        rec.hoko_part_value_bc = (rec.effective_performance_value_bc * (data.hoko_part_percentage / 100)).toFixed(2)
        rec.affiliate_part_value_bc = (rec.effective_performance_value_bc * (data.affiliate_part_percentage / 100)).toFixed(2)
        rec.wl_part_value_bc = (rec.effective_performance_value_bc * (data.wl_part_percentage / 100)).toFixed(2)
        rec.trader_part_value_bc = (rec.effective_performance_value_bc * (data.trader_part_percentage / 100)).toFixed(2)

        this.recalcRow = {...rec};
      },
      prepareExportData(resultList) {
        this.exportHeader = [
            `${this.$t('status')}`,
            `${this.$t('date')}`,
            `${this.$t('account')}`,
            `${this.$t('payer')} ${this.$t('fullname')}`,
            `${this.$t('amount')}`,
            `${this.$t('percent_from_total')}`,
            `${this.$t('strategytxt')}`,
        ];
        this.exportData = resultList.map(obj => {

          let amount = 0.0;
          let percent_from_total = 0.0;

          if (this.isFollower(obj)) {
            amount = parseFloat(obj.effective_performance_value_usd).toFixed(2)
            percent_from_total = Math.round(
                this.getPercentage(
                    obj.watermark_performance_value_bc,
                    obj.watermark_performance_value_bc
                )
            )
          } else if (this.isTrader(obj)) {
            amount = parseFloat(obj.trader_part_value_usd).toFixed(2)
            percent_from_total = obj.trader_part_value_usd
          } else if (this.isAffiliate(obj)) {
            amount = parseFloat(obj.affiliate_part_value_usd).toFixed(2)
            percent_from_total = obj.affiliate_part_percentage
          } else if (this.isWhitelabel(obj)) {
            amount = parseFloat(obj.wl_part_value_usd).toFixed(2)
            percent_from_total = obj.wl_part_percentage
          }

          return [
            this.handleType(obj).label,
            obj.date_reference,
            obj.follower_account,
            obj.follower_full_name,
            amount,
            percent_from_total,
            obj.strategy_name
          ];
        });
      },
    },
    watch: {
      "date_reference": function (val) {
        if (val instanceof Object) {
          this.filters.date_start = this.$moment(val[0]).format('YYYY-MM-DD')
          this.filters.date_end = this.$moment(val[1]).format('YYYY-MM-DD')
        }
      },
    },
  }

</script>

<style scoped lang="scss">
@import 'src/assets/sass/paper/variables';
#billingMarketplaceDisputeModal {
  textarea.form-control {
    padding: 1rem;
    line-height: 1.5rem;
    max-height: 20rem;
  }
}

.el-date-editor .el-range-separator {
  width: 11% !important;
}

.input-group.input-group-focus .input-group-prepend .input-group-text, .input-group.input-group-focus .input-group-append .input-group-text
{
  border-color: #DFDFDF !important;
}


.p-badge {
  width: 100%;
  display: inline-block;
  line-height: $font-size-small !important;
  font-size: $font-size-mini !important;
  margin-bottom: 0 !important;
  &.charged {
    &.badge-success {
      color: $success-states-color;
      background-color: $white-bg;
      border-color: $success-states-color;
    }

    &.badge-danger {
      color: $danger-states-color;
      background-color: $white-bg;
      border-color: $danger-states-color;
    }
  }
}
.action-button {
  button {
    font-size: small;
    min-width: 3rem;
    &.btn.btn-default {
      font-size: smaller;
    }
  }
}
.textarea {
  width: 100%;
  padding-left: 1rem;
  margin-top: 0.25rem;
  max-height: 8rem;
  overflow: auto;
}
.col-centered {
  float: none;
  margin: 0 auto;
  text-align: center;
}
.item-list::v-deep {
  margin-left: -1.5rem;
  &.recharging {
    .value {
      .recalc {
        font-size: smaller;
        color: $danger-color;
        &:before {
          content: '\21D2';
          margin-left: 0.25rem;
        }
      }
    }
  }
  .label {
    font-weight: bold;
    min-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .value {
    font-weight: bold;
    text-align: right;
    .fg-numeric {
      position: relative;
      display: inline-block;
      right: -0.25rem;
      height: 0;
      width: 9rem;
      input {
        height: 1.5rem;
      }
      .el-input-number__decrease,
      .el-input-number__increase {
        i {
          transform: translateY(15%) !important;
        }
      }
    }
  }
  hr {
    width: 100%;
    margin-top: 0.85rem;
    border-left: $border $light-gray;
  }
}
@media screen and (max-width: 768px) {
  .item-list::v-deep {
    font-size: small;
    .value {
      .fg-numeric {
        input {
          height: 0.75rem !important;
        }
        .el-input-number__decrease,
        .el-input-number__increase {
          i {
            transform: translateY(0%) !important;
          }
        }
      }
    }
  }
}

span.ellipsis {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.btn {
  margin: 0px !important;
}
</style>
